.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  .pagination li {
    display: inline-block;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .pagination li.active a {
    color: #fff;
    background-color: #2563eb;
    border-radius: 4px;
    padding: 6px 12px;
  }
  
  .pagination li a {
    color: #2563eb;
    padding: 6px 12px;
    border: 1px solid #2563eb;
    border-radius: 4px;
  }
  
  .pagination li a:hover {
    background-color: #2563eb;
    color: #fff;
  }