
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components{
    .classSomething {
        @aplly flex gap-8
    }
}